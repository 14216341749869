import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {User, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const USER_URL = `${API_URL}/user`
const GET_USERS_URL = `${API_URL}/users/query`

const GET_SPACES = process.env.REACT_APP_API_URL + '/spaces'
const GET_SPACE = process.env.REACT_APP_API_URL + '/space'

const getUsers = (query: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_USERS_URL}?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getUserById = (id: ID): Promise<User | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const createUser = (user: User): Promise<User | undefined> => {
  return axios
    .put(USER_URL, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUser = (user: User): Promise<User | undefined> => {
  return axios
    .post(`${USER_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const getCompanies = (query?: string): Promise<UsersQueryResponse> => {
  if(query) 
    return axios.get(`${GET_SPACES}?search=${query}`).then((d: AxiosResponse<UsersQueryResponse>) => d.data)
  return axios.get(`${GET_SPACES}`).then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getCompanyReport = (query?: string): Promise<UsersQueryResponse> => {
  return axios.get(`${GET_SPACE}/${query}/task-report`).then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getUserReport = (query: string, subtasks: boolean): Promise<UsersQueryResponse> => {
  return axios.get(`${process.env.REACT_APP_API_URL}/user/${query}/task-report?subtasks=${subtasks}`).then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

export {
  getUsers,
  deleteUser,
  deleteSelectedUsers,
  getUserById,
  createUser,
  updateUser,
  getCompanies,
  getCompanyReport,
  getUserReport
}
